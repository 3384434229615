@-webkit-keyframes AnimationName {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@-moz-keyframes AnimationName {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes AnimationName {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

html,
body {
    margin: 0;
    padding: 0;
    background: linear-gradient(270deg, #5e8596, #829ea9, #b06673, #ce6e70, #7f5468);
    background-size: 1000% 1000%;
    -webkit-animation: AnimationName 30s ease infinite;
    -moz-animation: AnimationName 30s ease infinite;
    animation: AnimationName 30s ease infinite;
}
body {
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    color: white;
    font-size: 20px;
    /* this doesnt do much */
    /* display: flex;
    flex-direction: column; */
}

div#root {
    
    display: block;
    text-align: center;
}

div#root > * {
    margin-left: auto;
    margin-right: auto;
}

div#schoolOptions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    max-width: 500px;
}

/* from editableField Component */
.editableField {
    display: inline-flex;
    justify-content: space-between;
    /* width: 100%; */
    flex-grow: 1;
    text-align: left;
    margin: 2px

}
.editableFieldLabel {
    margin: 5px;
}

div#page {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.timeFont {
    font-family: "Play", Courier, monospace;
    font-weight: bold;
}

.centeredInline {
    margin: 0 auto;
    text-align: center;
}

.bottomSpace {
    margin-bottom: 14;
}
.topSpace {
    margin-top: 14;
}

.cornerNavButton {
    font-size: 32px; /*Default size */
    position: absolute;
    
    padding-right: 0.15em;
    padding-left: 0.15em;
}

.statusIndicator {
    /* font-size: 32px; Default size */
    position: absolute;
    color: whitesmoke;
    background-color: gray;
    
    padding-right: 0.15em;
    padding-left: 0.15em;
}
.statusIndicator > * {
    margin: .1em;
}

.cornerNavButton:hover {
    background-color: grey;
}


.cornerNavTop {
    top: 0;
}

.cornerNavBottom {
    bottom: 0;
}

.cornerNavLeft {
    left: 0;
    border-bottom-left-radius: 0;
}

.cornerNavRight {
    right: 0;
    border-bottom-right-radius: 0;
}

.cornerNavTop.cornerNavLeft {
    border-bottom-right-radius: 10px;
}

.cornerNavTop.cornerNavRight {
    border-bottom-left-radius: 10px;
}

.cornerNavBottom.cornerNavLeft {
    border-top-right-radius: 10px;
}

.cornerNavBottom.cornerNavRight {
    border-top-left-radius: 10px;
}

.smallerText {
    font-size: 0.85em;
}

.smallIcon {
    font-size: 32px;
}

.mediumicon {
    font-size: 48px;
}

.largeIcon {
    font-size: 64px;
}

a:hover,
a:active {
    font-weight: bold;
}

a {
    color: whitesmoke;
    text-decoration: underline;
}

/* exclude anything under full calendar (in the admin panel) from this styling */
.fc a {
   color:initial;
   text-decoration: none;
}

h1.bigger {
    /* regular h1's are 2em */
    font-size: 3em;
}

section#credits > a {
    margin: 5px;
}

section#options > * {
    display: block;
    margin-top: 2vw;
}

/* #flash {
    /* flex: 1; *
    display: none;
    margin: 0 auto;
    display: block;
    text-align: center;
    width: 100vw;
    /* border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem; *
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

#flash a {
    font-weight: 700;
    text-decoration: none;
}
#flash a:hover {
    text-decoration: underline;
}

#flash.success {
    background-color: #d4edda; /* copied from bootstrap *
    color: #155724;
}

#flash.info {
    color: #0c5460; /* copied from bootstrap *
    background-color: #a7d6f5;
}

#flash.info a {
    color: #002752;
}

#flash.warning {
    background-color: #faf0ce;
    color: #856404;
}

#flash.danger {
    background-color: #f38c95;
    color: #721c24; /* copied from bootstrap *
} 

.alert-link {
    font-weight: 700;
}*/


.blockButton {
    padding: 5px;
    margin: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    display: block;
}

.verticalFlex {
    display: flex;
    flex-direction: column;
}

.horizontalFlex {
    display: flex;
    justify-content: center;
}

.horizontalFlex > * {
    /* Important is used to override inline styles for the table and key */
    margin: .5em !important;
}

.footer__social {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer__social li {
    display: inline-block;
    font-size: 15px;
    padding: 0;
    margin-right: 12px;
}

.footer__social li a {
    display: block;
    font-size: 48px;
    /* line-height: 32px; */
    text-align: center;
    /* background-color: rgba(255, 255, 255, 0.03); */
    color: #ffffff;
    /* border-radius: 50%; */
}

.footer__social li a:hover,
.footer__social li a:focus,
.footer__social li a:active {
    /* background-color: #5b4f96; */
    color: #c7c7c7;
    font-weight: normal;
}

.footer__social li:last-child {
    margin-right: 0;
}

.e-mail::after {
    content: "\0061\0064\0072\0069\0061\006E\0040\0061\0064\0072\0069\0061\006E\0063\0065\0064\0077\0061\0072\0064\0073\002E\0063\006F\006D";
}


.mediumVerticalSpacer {
    height:10vh;
}

@media (min-height: 350px) {
    .mediumVerticalSpacer {
        height: 25vh;
    }
}

@media (min-height: 500px) {
    .mediumVerticalSpacer {
        height: 50vh;
    }
}


.centeredWidth {
    margin: 0 auto;
    width: 95vw;
}

@media (min-width: 768px) {
    .centeredWidth {
        width: 60vw;
    }

}

@media (min-width: 992px) {
    .centeredWidth {
        width: 40vw;
    }
}

@media (min-width: 1400px) {
    .centeredWidth {
        width: 30vw;
    }
}
.circle {
    border-radius: 50%;
    display: inline-block;
}